.header {
    background-color: #2c344a;
    border: none
}

.cs-conversation-header__user-name {
    background: transparent !important;
    text-align: center;
    color: #fcfcfd !important;
    padding: 0.3rem;
}

.messageList {
    background: url("../../public/contactus_2_bk.png");
    background-size: cover;
    background-position: center;

}

.cs-conversation-header__content {
    align-items: center;
}

.cs-message--incoming .cs-message__content {
    background-color: #e8eae1;
}

.cs-message--outgoing .cs-message__content {
    background-color: #7bd13d;
}

.cs-message__content,
.cs-message__html-content,
.cs-message-input__content-editor,
.cs-message-input__content-editor-container,
span
{
    font-family: 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 300;
    font-size: 1rem;

    color: black;
}

.cs-message__content-wrapper {
    filter: drop-shadow(1px 2px 8px var(--shadow-color));

    --shadow-color: hsl(220deg 60% 50%);
}

.cs-message-input__content-editor-wrapper {
    background-color: #f7f9fd;
}

.cs-message-input__content-editor,
.cs-message-input__content-editor-container {
    background-color: transparent !important;
}

.cs-button cs-button--send {
    color: #5182d3;
}

.cs-conversation-header__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
}

.cs-conversation-header__content button {
    border-radius: 10px;
    padding: 5px;
    border: #f7f9fd 2px solid;
    background: transparent;
}

.cs-conversation-header__content button span {
    font-weight: 500;
    color: white;
}

.typing__dot {
    float: left;
    width: 8px;
    height: 8px;
    /* margin: 0 4px; */
    background: #8d8c91;
    border-radius: 50%;
    opacity: 0;

    animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
    animation-delay: 0s;
}

.typing__dot:nth-child(2) {
    animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
    animation-delay: 0.4s;
}