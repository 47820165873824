@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
    scroll-padding-top: 18vh;
    scroll-behavior: smooth;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #464e4e #fff;
}

body {
    /* background-color: #120f0f; */
    /* display: flex;
    flex-direction: column; */
}

a {
    font-family: 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

h1 {
    font-family: 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bolder;
    font-size: 1.7rem;

    /* color: white; */
}

h2 {
    font-family: 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bolder;
    font-size: 1.3rem;

    /* color: white; */
}

strong {
    font-weight: 900;
}

p {
    font-family: 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 300;
    font-size: 1.1rem;

    /* color: white; */
}

@layer utilities {
    .text-balance {
        text-wrap: balance;
    }
}

/* 
#about {
    align-items: flex-end;

    background-image: linear-gradient(70deg, rgba(0, 0, 0, .3) 0, rgba(0, 0, 0, .7)), url(/images/aboutBg.png);
    background-size: cover;
    background-position: center;
}

#projects {
    align-items: center;

    background-image: linear-gradient(180deg, rgba(0, 0, 0, .6) 0, rgba(0, 0, 0, .0)), url(/images/complex.png);
    background-size: cover;
    background-position: center;
}

#contact {
    flex-direction: row;
    align-items: center;
} */

.jss1 {
    position: absolute;
    width: 100px;
    height: 100px
}

.disabled {
    opacity: 0.3;
}

.disabled:after {
    width: 100%;
    height: 100%;
    position: absolute;
}