body.home-body {
  margin: 0;
  overflow: hidden;
  /* This will hide the scrollbar */
}

.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

#navbar-custom {
  min-height: 9vh;
}

#navbar-custom a {
  font-family: 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: 1.5rem;

  transition: color 0.23s ease;
}

#navbar-custom a:hover {
  color: #46a8df!important;
}

#logo {
  width: 40%;
  margin-bottom: 2.2rem;
}

.user-msg {
  justify-content: flex-end;
}

.bubble {
  text-align: left;
}

.user-msg > .bubble {
  background-color: #46a8df;
}

.ai-msg {
  justify-content: flex-start;
}

.ai-msg > .bubble {
  background-color: #F7972C;
}

.disabled {
  opacity: 0.3;
}

.disabled:after {
  width: 100%;
  height: 100%;
  position: absolute;
}

.typing__dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: #8d8c91;
  border-radius: 50%;
  opacity: 0;

  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}

strong {
  font-weight: bold;
}