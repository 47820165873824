@media only screen and (max-width: 825px) {


    /* .header {
        background-color: rgb(226, 44, 44);
        border: none
    } */

    .messageList {
        background: url("../../public/contactus_2_bk_sp.png");
        background-size: cover;
        background-position: top -670px right;

    }

}